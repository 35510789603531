import { useMutation } from '@tanstack/vue-query';
import { validatorFactory } from '@laam/lib/validator';
import * as Sentry from '@sentry/vue';

import type { BookingsResponse } from '~/types/Product.ts';
import { notifyBookingsSchema } from '~/types/Product.ts';

interface Payload {
	email: string;
	product_shopify_id: number;
	product_url: string;
}

const bookingsValidator =
	validatorFactory<BookingsResponse>(notifyBookingsSchema);

export const useNotifyMeBooking = () => {
	const { data, error, isPending, status, mutate } = useMutation({
		mutationKey: ['notify_me'],
		gcTime: Infinity,
		mutationFn: async (payload: Payload) => {
			const response = await fetch('https://api.laam.pk/products/bookings/', {
				method: 'POST',
				body: JSON.stringify(payload),
				headers: {
					'Content-Type': 'application/json',
				},
			});

			if (response.status === 201) {
				try {
					const data = await response.json();
					return bookingsValidator.verify(data);
				} catch (e) {
					const err = new Error(`Bookings repsonse validation failed! ${e}`);
					Sentry.captureException(err, (scope) => {
						scope.setContext('errors', {
							errors: err,
						});
						return scope;
					});
					throw err;
				}
			} else {
				const err = new Error('Failed to create booking');
				Sentry.captureException(err, (scope) => {
					scope.setContext('email', {
						...payload,
					});
					return scope;
				});
				throw err;
			}
		},
	});

	return {
		data,
		error,
		status,
		isPending,
		mutate,
	};
};
